<template>
  <v-dialog v-model="dialogOpen"
    persistent
    max-width="800"
    @keydown.esc="close">
    <v-overlay v-show="loading"
      absolute
      z-index="10">
      <v-progress-circular indeterminate
        size="64"
        color="primary"></v-progress-circular>
    </v-overlay>
    <v-card class="pb-5"
      style="overflow-x: hidden"
      :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }">
      <v-card-title class="d-flex"
        style="vertical-align: middle">
        <v-row class="pa-3 mb-2">
          <h3 class="text--secondary">Podaci o narudžbi</h3>
          <v-spacer></v-spacer>
          <v-icon large
            @click="close">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-container fluid
        class="
          d-flex
          justify-center
          align-center
          flex-wrap
          pa-0
          mt-0
          fill-height
        ">
        <v-row style="margin-top: 5px"
          class="justify-center pa-0 ma-0">
          <v-col cols="4"
            sm="3"
            md="3"
            xl="2"
            class="pa-0 ma-0">
            <v-radio-group v-model="checkRadio"
              class="pa-0 ma-0"
              @change="changeRadioValue">
              <v-radio class="pa-0 ma-0"
                v-for="val in radioValues"
                :key="val.value"
                :value="val.value"
                :label="val.name"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="4"
            sm="3"
            md="2"
            xl="2"
            :class="['px-0', 'pa-0', 'ma-0']">
            <v-select class="pa-0 ma-0"
              v-if="showLocationSelector"
              label="Odaberite prodajno mjesto"
              v-model="selectedLocation"
              :items="locations"
              item-text="name"
              item-value="value"
              return-object
              @change="setWarehouseChoices">
            </v-select>
            <v-select class="pa-0 ma-0"
              label="Odaberite skladište"
              v-model="selectedWarehouse"
              :items="warehouses"
              item-text="name"
              item-value="value"
              return-object>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="12"
            sm="11"
            lg="11"
            :class="['px-0']"
            v-if="order !== undefined">
            <h4>
              Broj narudžbe:
              {{ order.orderId }}
            </h4>
            <v-row class="pa-0 ma-0">
              <h4 class="pt-1">Adresa:</h4>
              <v-col cols="6"
                class="pa-0 ma-0">
                <v-select dense
                  style="font-size: 10px; font-weight: bold"
                  class="pa-0 ma-0 pl-2"
                  :items="addresses"
                  v-model="address"
                  item-text="name"
                  item-value="value">
                </v-select>
              </v-col>
            </v-row>
            <h4>
              Naručio:
              {{ order.createSystemUser.name.toUpperCase() }}
            </h4>

            <v-row class="pa-0 ma-0">
              <h4 class="pt-1">Način plaćanja:</h4>
              <v-col cols="3"
                class="pa-0 ma-0">
                <v-select dense
                  style="font-size: 10px; font-weight: bold"
                  class="pa-0 ma-0 pl-2"
                  :items="receiptTypes"
                  v-model="receiptType"
                  item-text="name"
                  item-value="value">
                </v-select>
              </v-col>
            </v-row>
            <br />
            <v-data-table v-if="
              order !== undefined &&
              order.products !== undefined &&
              order.products.length > 0
            "
              :headers="admin ? orderHeadersAdmin : orderHeadersSeller"
              :items="order.products"
              :items-per-page="5"
              :search="searchTable"
              class="elevation-1 pb-5 mb-5"
              hide-default-footer
              :custom-filter="filterOnlyCapsText">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Proizvodi</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-text-field v-model="searchTable"
                    label="Pretraži proizvode"
                    class="mt-4"></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr v-if="admin">
                  <td>
                    <v-img v-if="item.imageUrl"
                      :src="item.imageUrl"
                      contain
                      height="48"></v-img>
                  </td>
                  <td>{{ item.articleID }}</td>
                  <td>{{ item.articleName }}</td>
                  <td>{{ item.unitPrice | money(1, currency) }}</td>
                  <td>
                    {{
                      (item.unitPrice - item.unitPriceWithDiscount)
                      | money(1, currency)
                    }}
                  </td>
                  <td>{{ item.unitQuantity }}</td>
                  <td>
                    {{
                      (parseInt(item.quantity) * item.unitPriceWithDiscount)
                      | money(1, currency)
                    }}
                  </td>

                  <td>
                    <v-text-field v-model="item.quantity"
                      outlined
                      flat
                      dense
                      @change="changedQuantity(item)"></v-text-field>
                  </td>
                </tr>
                <tr v-else>
                  <td>
                    <v-img v-if="item.imageUrl"
                      :src="item.imageUrl"
                      contain
                      height="48"></v-img>
                  </td>
                  <td>{{ item.articleID }}</td>
                  <td>{{ item.articleName }}</td>
                  <td>{{ item.unitQuantity }}</td>
                  <td>{{ (item.quantity / item.unitQuantity).toFixed() }}</td>
                  <td>
                    <v-text-field v-model="item.quantity"
                      outlined
                      flat
                      dense
                      @change="changedQuantity(item)"></v-text-field>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <div class="text-right">
              <h4 v-if="admin">
                Iznos narudžbe:
                {{ order.totalOrderPrice | money(1, currency) }}
              </h4>
              <br />
              <v-btn right
                bottom
                class="white--text loginButton"
                :disabled="selectedWarehouse !== '' && selectedWarehouse !== undefined
                  ? false
                  : true
                  "
                @click="createDeliveryNote()">
                Kreiraj
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>

import rules from '@/plugins/rules'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import { clone } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'
import { callAssociates } from '@/api/associates'

export default {
  components: {
  },
  mixins: [flow, applicationSettings],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'openPdfPrint',
    'closePdfPrint'
  ],
  data: function () {
    return {
      admin: false,
      dialogOpen: false,
      loading: false,
      order: undefined,
      location: {},
      locations: [],
      associates: [],
      lastVisible: {},
      selected: {},
      multiplier: 0,
      searchTable: undefined,
      show: false,
      addresses: [
      ],
      address: undefined,
      receiptTypes: [],
      receiptType: undefined,
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      orderHeadersAdmin: [
        {
          text: '',
          value: 'imageUrl'
        },
        {
          text: 'Id artikla',
          value: 'articleName'
        },
        {
          text: 'Naziv artikla',
          value: 'articleName'
        },
        {
          text: 'Jedinična cijena artikla',
          value: 'unitDiscountPrice'
        },
        {
          text: 'Popust',
          value: 'discount'
        },
        {
          text: 'Količina u paketu',
          value: 'unitQuantity'
        },
        {
          text: 'Cijena za količinu',
          value: 'quantityPrice'
        },
        {
          text: 'U narudžbi',
          value: 'quantity'
        }
      ],
      orderHeadersSeller: [
        {
          text: '',
          value: 'imageUrl'
        },
        {
          text: 'Id artikla',
          value: 'articleName'
        },
        {
          text: 'Naziv artikla',
          value: 'articleName'
        },
        {
          text: 'Količina u paketu',
          value: 'unitQuantity'
        },
        {
          text: 'Broj paketa',
          value: 'quantityPackage'
        },
        {
          text: 'U narudžbi',
          value: 'quantity'
        }
      ],
      checkRadio: '',
      showLocationSelector: false,
      choosenText: '',
      warehouses: [],
      selectedWarehouse: '',
      currency: 'EUR'
    }
  },
  computed: {
    radioValues () {
      return [
        { name: 'Na firmi', value: 'company' },
        { name: 'Na prodajnom mjestu', value: 'pointOfSale' },
        { name: 'Sva', value: 'all' }
        // { name: '$vuetify.languages.en', value: 'en' }
      ]
    },
    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    },
    locs: {
      get: function () {
        return this.locations
      },
      set: function (nv) {
        this.locations = [
          { name: this.$t('$vuetify.all'), id: '', location_id: '' }
        ].concat(nv)
      }
    }
  },
  watch: {
    location: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          // paginate more columns
          this.lastVisible = {}
        }
      }
    }
  },
  async mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
    this.admin = state.getUser()?.roles?.SUPERADMIN || state.getUser()?.roles?.ADMIN
    this.getAssociates()
    this.getMultiplier()

    // DOHVATI SVA SKLADISTA I PODATKE O NJIMA
    await this.getAllWarehouses()

    this.locations = [
      { name: this.$t('$vuetify.all'), id: '', location_id: '' }
    ]
    this.location = this.locations[0]

    const docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    const listener = docRef.onSnapshot(doc => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        this.locs = Object.keys(doc.data().locations).map(key => {
          return doc.data().locations[key]
        })
      }
    })
    this.listeners.push(listener)
    this.filterWarehouses()
  },
  methods: {
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search.toUpperCase() != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search.toUpperCase()) !== -1
    },
    calculateAll () {
      this.order.totalOrderPrice = 0
      this.order.products.forEach(product => {
        this.order.totalOrderPrice += product.quantity * product.unitPriceWithDiscount
      })

      this.$forceUpdate()
    },
    changedQuantity (item) {
      const quantity = item.quantity
      this.order.products.forEach((it, key) => {
        if (item.articleID === it.articleID) {
          if (quantity === 0 || quantity === '0') {
            delete this.order.products[key]
          } else {
            this.order.products[key].quantity = quantity
          }
        }
      })

      const tmp = this.order.products
      this.order.products = []
      this.order.products = tmp
      this.calculateAll()

      this.$forceUpdate()
    },
    open (order) {
      this.order = order

      this.addresses = [
        {
          name: this.order.address.streetAndNumber.toUpperCase() +
            ', ' +
            this.order.address.postalCode +
            ' ' +
            this.order.address.city.toUpperCase(),
          value: this.order.address.addressId
        }
      ]

      this.receiptTypes = [
        {
          name: this.order.receiptType,
          value: this.order.receiptType
        }
      ]

      this.address = this.addresses[0]
      this.receiptType = this.receiptTypes[0]
      this.dialogOpen = true
    },
    applyFilter () {
      this.lastVisible = {}
      this.orders = []
      this.$refs.InfiniteLoading.stateChanger.reset()
    },

    navigateToFlow () {
      this.$router.push({
        name: 'Home',
        params: {
        }
      })
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    async getAssociates () {
      var path = `/associates/${state.getCurrentCompany().id}/associate`
      var response = await callAssociates('get', path)

      if (response && response.status === 200 && response.data && response.data.associates) {
        this.defaultAssociates = Object.keys(response.data.associates)
          .map((key) => {
            return response.data.associates[key]
          })
          .filter((associate) => associate.status === 'OK' && associate.associate_type.BUYER)

        this.associates = clone(this.defaultAssociates)
      } else if (response.status === 404) {
        this.associates = []
        this.showMsgBox({
          text: 'Klijenti nisu pronađeni.',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'warning'
        })
      } else {
        this.showMsgBox({
          text: 'Neuspjelo dohvaćanje klijenata',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },

    close () {
      this.dialogOpen = false
    },
    createDeliveryNote () {
      const reqId = uuidv4()

      this.loading = true

      const extendedItems = []
      this.order.products.forEach(item => {
        const itemId = uuidv4()

        const newItem = {
          id: itemId,
          item: {
            name: item.articleName,
            code: item.articleID.toFixed(),
            picture: item.imageUrl,
            id: itemId,
            selling_unit: 'KOMAD',
            status: 'OK',
            storage_unit: 'KOMAD',
            type: 'GOODS',
            stock: item.quantity * this.multiplier
          },
          warehouse_data: {
            ean: '',
            item_id: itemId,
            item_key: '',
            item_name: item.articleName,
            location_key: this.selectedWarehouse?.warehouse?.location?.id ? this.selectedWarehouse.warehouse?.location?.id : '',
            manufacturer_item_key: item.articleID.toFixed(),
            margin: 0,
            margin_amount: 0,
            measure_unit: 'KOMAD',
            partner_item_key: item.articleID.toFixed(),
            pretax: 0,
            pretax_amount: 0,
            purchase_price: item.unitPriceWithDiscount * 100,
            quantity: item.quantity * this.multiplier,
            quantity_on_hand: item.quantity * this.multiplier,
            quantity_on_order: 0,
            rebate_amount: (item.unitPrice - item.unitPriceWithDiscount) * 100,
            retail_price: 0,
            sale_price: 0,
            short_description: '',
            stock_days: 0,
            supplier_price: item.unitPrice * 100,
            supplier_rebate: (((item.unitPrice - item.unitPriceWithDiscount) / item.unitPrice) * 100) * 100,
            value: (item.unitPriceWithDiscount * item.quantity) * 100,
            vat: 0,
            vat_amount: 0,
            wholesale_price: 0,
            price_without_vat: 0
          }
        }

        newItem.item.stock = parseInt(item.quantity)

        extendedItems.push(newItem)
      })

      try {
        const payload = {
          action: {
            operation: 'set',
            entity: 'roto_document',
            params: {
              document_id: this.order.orderId.toFixed(),
              id: 'ddf7ea58-2988-4d4a-9240-7f462d3bccae',
              warehouse_id: this.selectedWarehouse.value,
              warehouse_name: this.selectedWarehouse.name,
              comment: '',
              customer_address: 'Ulica grada Wirgesa 14',
              customer_attention: '',
              customer_city: 'Samobor',
              customer_key: '',
              customer_mail: 'info@rotodinamic.hr',
              customer_name: 'roto',
              customer_oib: '24723122482',
              customer_order_number: this.order.orderID,
              customer_phone: '01/3903-440',
              customer_pono: '',
              customer_tax_id: '',
              customer_zip: '10430',
              delivery_number: this.order.orderID,
              due_date: 0,
              fob_description: '',
              fob_key: '',
              location_name: this.selectedWarehouse.warehouse?.location?.name !== '' ? this.selectedWarehouse.warehouse?.location?.name : '',
              order_date: 0,
              quality_check_level_id: '',
              request_date: 0,
              ship_address: this.selectedWarehouse.warehouse?.address !== '' ? this.selectedWarehouse.warehouse?.address : '',
              ship_city: this.selectedWarehouse.warehouse?.city !== '' ? this.selectedWarehouse.warehouse?.city : '',
              ship_date: 0,
              ship_doc_number: '',
              ship_name: '',
              ship_to_key: '',
              ship_zip: '',
              tran_number: '',
              vendor_id: '',
              vendor_key: '',
              company_id: state.getCurrentCompany().id,
              doc_type: 'deliverynote',
              location_id: this.selectedWarehouse.warehouse?.location?.id !== '' ? this.selectedWarehouse.warehouse?.location?.id : '',
              extended_items: extendedItems
            }
          }
        }

        var userID = auth.currentUser.uid

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${userID}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'warehousedocument',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .catch((err) => {
            this.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
          })
        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot((doc) => {
          if (this.$refs && doc.data()) {
            const resp = doc.data()
            if (resp !== undefined && resp.header !== undefined && resp.header.code === 200) {
              this.showMsgBox({
                text: 'Primka uspješno kreirana!',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
              this.close()
              this.$emit('deliveryNoteDone')
            } else {
              this.showMsgBox({
                text: 'An error has occurred',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
            }
            this.loading = false
            this.close()
            unsubscribe()
          }
        })
      } catch (err) {
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.loading = false
      }
    },

    changeRadioValue () {
      this.choosenText = ''
      this.selectedWarehouse = ''
      this.showLocationSelector = false
      switch (this.checkRadio) {
        case 'pointOfSale':

          // FILTER ZA IZMJENU RADIO BUTTONA - NE INICIJALNI
          // PRVI SLUCAJ AKO SI NA DOKUMENTE DOSAO PREKO LOKACIJE - SETIRAJ TU LOKACIJU I NJENA SKLADISTA ZA ODABIR
          // DRUGI SLUCAJ AKO SI NA DOKUMENTE DOSAO PREKO FIRME - PROVJERI DA LI SI PRIJE SETIRAO LOKACIJU I AKO JESI SETIRAJ NJENA SKLADISTA
          // TRECI SLUCAJ AKO NEMAS SETIRANU LOKACIJU OBRISI DOSTUPNA SKLADISTA
          if (this.locations.length > 0 && state.getPointOfSale().id !== undefined && state.getPointOfSale().id !== '') {
            this.locations.forEach(location => {
              if (location.id === state.getPointOfSale().id) {
                this.selectedLocation = location

                this.warehouses = this.allWarehouses
                this.warehouses = this.warehouses.filter(wh => {
                  if (wh.warehouse.location_id === this.selectedLocation.id) {
                    return wh
                  }
                })
              }
            })
          } else if ((state.getPointOfSale().id === undefined || state.getPointOfSale().id === '') && this.selectedLocation !== undefined && this.selectedLocation.id !== undefined) {
            this.warehouses = this.allWarehouses
            this.warehouses = this.warehouses.filter(wh => {
              if (wh.warehouse.location_id === this.selectedLocation.id) {
                return wh
              }
            })
          } else {
            this.warehouses = []
          }

          this.showLocationSelector = true
          break
        case 'company':
          this.warehouses = this.allWarehouses
          this.warehouses = this.warehouses.filter(wh => {
            if (wh.warehouse.location_id === '') {
              return wh
            }
          })
          break
        case 'all':
          this.warehouses = this.allWarehouses
      }
    },
    filterWarehouses () {
      this.warehouses = this.allWarehouses
      switch (this.checkRadio) {
        case 'company':
          this.warehouses = this.warehouses.filter(wh => {
            if (wh.warehouse.location_id === '') {
              return wh
            }
          })
          break
        case 'pointOfSale':
          this.warehouses = this.warehouses.filter(wh => {
            if (wh.warehouse.location_id === this.selectedLocation.id) {
              return wh
            }
          })
          break
        case 'all':
        // ALREADY DONE
      }
    },
    async getAllWarehouses () {
      const query = df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('status', '==', 'OK')
      query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          const warehouse = doc.data()
          this.warehouses.push({ name: warehouse.name, value: warehouse.id, warehouse: warehouse })
        })
      })
      this.allWarehouses = this.warehouses
    },
    setWarehouseChoices () {
      this.warehouses = this.allWarehouses
      this.warehouses = this.warehouses.filter(wh => {
        if (wh.warehouse.location_id === this.selectedLocation.id) {
          return wh
        }
      })
    }
  }
}

</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}

tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
